<template>
  <div class="q-mr-lg q-ml-lg text-justify">
    <h6 class="text-subtitle2">
      {{ $t("login.recover.titleConfirm") }}
    </h6>
    <p class="text-subtitle3">
      {{ $t(text_orientation) }}
    </p>
    <p class="text-subtitle3 q-mb-lg">
      {{ $t("login.recover.orientation_text") }}
    </p>
  </div>
  <Form
    class="q-gutter-md"
    :initial-values="initialValues"
    :validation-schema="schema"
    @submit="onSubmit"
  >
    <q-card-section>
      <Field name="code" v-slot="{ errorMessage, value, field }">
        <q-input
          autocomplete="on"
          filled
          :label="labels.code"
          :model-value="value"
          v-bind="field"
          :error-message="errorMessage"
          :error="!!errorMessage"
        />
      </Field>

      <q-btn
        class="q-mt-md q-mb-lg bg-custom-color"
        type="submit"
        :label="$t('login.recover.submit')"
        :loading="in_request"
        :disable="in_request"
      />
    </q-card-section>
  </Form>
</template>

<script>
import { useActions, useGetters } from "vuex-composition-helpers";
import { rules, labels } from "@/shared/form-presets";
import { useRouter, useRoute } from "vue-router";
import { Field, Form } from "vee-validate";
import { Notify } from "quasar";
import * as yup from "yup";
import { ref } from "vue";

export default {
  name: "RecoverConfirm",

  components: {
    Field,
    Form,
  },

  setup() {
    const router = useRouter(),
      route = useRoute(),
      df_text = "login.recover.orientation",
      text_orientation = ref(df_text);

    const in_request = ref(false),
      schema = (() => {
        const { code } = rules;
        return yup.object({ code });
      })(),
      initialValues = {};

    const { sendCodeAction } = useActions({
        sendCodeAction: "auth/sendCodeAction",
      }),
      { partner } = useGetters({
        partner: "partner/getPartner",
      });

    if (route.query.channel && route.query.channel) {
      text_orientation.value =
        {
          sms: "login.recover.orientation_wpp",
          whatsapp: "login.recover.orientation_sms",
        }[route.query.channel] || df_text;
    }

    const onSubmit = (values, actions) => {
      in_request.value = true;
      sendCodeAction({
        code: values.code,
        email: route.params.email,
        slug: route.params.slug,
      })
        .then(() => {
          router.push({
            name: "recover.password.change",
            params: {
              code: values.code,
              email: route.params.email,
            },
          });
        })
        .catch(({ errors, message }) => {
          actions.setFieldValue("password", "");
          const notify = (error) => {
            Notify.create({
              message: error,
              position: "top-right",
              color: "red",
              icon: "fas fa-exclamation",
            });
          };

          if (errors) Object.values(errors).forEach(notify);
          if (message) notify(message);
        })
        .finally(() => {
          in_request.value = false;
        });
    };

    return {
      partner,
      text_orientation,
      in_request,
      labels,
      schema,
      initialValues,
      onSubmit,
    };
  },
};
</script>
